<template>
    <div class="user-profile">
        <h3 class="head-title">个人中心</h3>
        <div class="user-wrapper-box">
            <div class="basic-wrap">
                <div class="avatar-wrap">
                    <div class="mp-avatar-box" v-if="adminAvatar">
                        <img class="img" :src="adminAvatar">
                    </div>
                    <div class="mp-default-avatar" v-else>
                        {{adminRealnameShow}}
                    </div>
                    <a-upload
                        class="edit-btn"
                        name="file"
                        :before-upload="beforeUpload"
                        @change="uploadOnChange"
                        :showUploadList="false"
                        accept="image/*"
                    >
                        <span class="edit-text">修改头像</span>
                    </a-upload>
                </div>
                <div class="user-info">
                    <div class="text-line">
                        真实姓名<span class="item-text">{{adminRealname}}</span>
                    </div>
                    <div class="text-line">
                        手机号码<span class="item-text">{{adminCellphone}}</span>
                    </div>
                    <div class="text-line">
                        所属角色<span class="item-text">{{adminPosition}}</span>
                    </div>
                </div>
                <div>
                    <a-button type="primary" @click="editPwdVisible = true">修改密码</a-button>
                </div>
            </div>
        </div>
        <a-modal
            v-model="editPwdVisible"
            centered
            title="修改密码"
            @ok="editPwdOk"
            :maskClosable="false" 
            :destroyOnClose="true"
            :confirmLoading="editPwdLoading"
            :width="640"
        >
            <div class="pwd-wrap">
                <a-row>
                    <a-col :span="5"></a-col>
                    <a-col :span="19">
                        <div class="switch-pwd-type">
                            <span class="item" :class="{active: editPwdType === 2}" @click="onChangeType(2)">通过密码修改</span>
                            <span class="item" :class="{active: editPwdType === 1}" @click="onChangeType(1)">通过验证码修改</span>
                        </div>
                    </a-col>
                </a-row>
                <a-form-model
                    v-if="editPwdType === 2"
                    ref="pwdForm"
                    :rules="rulesPwd"
                    :model="pwdForm"
                    class="pwd-form"
                    label-align="left"
                    :label-col="{ span: 5 }"
                    :wrapper-col="{ span: 19 }"
                    autoComplete="off"
                >
                    <a-form-model-item label="旧密码" prop="old">
                        <a-input :type="pwdshow.old?'text':'password'" placeholder="请输入旧密码"
                            v-model="pwdForm.old" autoComplete="new-password"
                        >
                            <icEyeClose class="cursor" v-show="!pwdshow.old" slot="suffix" @click="pwdshow.old = true" />
                            <icEyeOpen class="cursor" v-show="pwdshow.old" slot="suffix"  @click="pwdshow.old = false" />
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item label="新密码" prop="new">
                        <a-input :type="pwdshow.new?'text':'password'" placeholder="请输入新密码"
                            v-model="pwdForm.new" autoComplete="new-password"
                        >
                            <icEyeClose class="cursor" v-show="!pwdshow.new" slot="suffix" @click="pwdshow.new = true"  />
                            <icEyeOpen class="cursor" v-show="pwdshow.new" slot="suffix" @click="pwdshow.new = false"  />
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item label="确认密码" prop="new2">
                        <a-input :type="pwdshow.new2?'text':'password'" placeholder="请重新输入密码"
                            v-model="pwdForm.new2" autoComplete="new-password"
                        >
                            <icEyeClose class="cursor" v-show="!pwdshow.new2" slot="suffix" @click="pwdshow.new2 = true"  />
                            <icEyeOpen class="cursor" v-show="pwdshow.new2" slot="suffix" @click="pwdshow.new2 = false"  />
                        </a-input>
                    </a-form-model-item>
                </a-form-model>
                <a-form-model
                    v-if="editPwdType === 1"
                    ref="codeForm"
                    :rules="rulesCode"
                    :model="codeForm"
                    class="pwd-form"
                    label-align="left"
                    :label-col="{ span: 5 }"
                    :wrapper-col="{ span: 19 }"
                    autoComplete="off"
                >
                    <a-form-model-item label="验证码" prop=code>
                        <a-input placeholder="请输入验证码" style="width: 68%;" v-model="codeForm.code">
                        </a-input>
                        <a-button class="mp-grey-btn" style="width: 30%; margin-left: 2%"
                            @click="getSmsCode"
                            :disabled="smsCaptcha.isWaiting"
                        >
                            {{smsCaptcha.showText}}
                        </a-button>
                    </a-form-model-item>
                    <a-form-model-item label="新密码" prop="new">
                        <a-input :type="codeshow.new?'text':'password'" placeholder="请输入新密码" v-model="codeForm.new"
                            autoComplete="new-password"
                        >
                            <icEyeClose class="cursor" v-show="!codeshow.new" slot="suffix" @click="codeshow.new = true"  />
                            <icEyeOpen class="cursor" v-show="codeshow.new" slot="suffix" @click="codeshow.new = false"  />
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item label="确认密码" prop="new2">
                        <a-input :type="codeshow.new2?'text':'password'" placeholder="请重新输入密码" v-model="codeForm.new2"
                            autoComplete="new-password"
                        >
                            <icEyeClose class="cursor" v-show="!codeshow.new2" slot="suffix" @click="codeshow.new2 = true"  />
                            <icEyeOpen class="cursor" v-show="codeshow.new2" slot="suffix" @click="codeshow.new2 = false"  />
                        </a-input>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </a-modal>
        
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import icEyeClose from '@/assets/icons/ic_eye_close.svg'
import icEyeOpen from '@/assets/icons/ic_eye_open.svg'
import {getSmsCaptcha} from '@/api/login'
import {updatePwd, updatePwdByPhone, updateAvatar} from '@/api/user'
import {validatePwd} from '@/utils/validate'
export default {
    components: {
        icEyeClose,
        icEyeOpen
    },
    computed: {
        ...mapGetters(['adminId', 'adminRealname', 'adminCellphone', 'adminPosition']),
        ...mapState({
            adminAvatar: state => state.user.adminAvatar
        }),
        adminRealnameShow: function() {
            return this.adminRealname.slice(this.adminRealname.length - 2)
        }
    },
    data() {
        let validatePwdNew = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error('请输入密码'))
            } else if (!validatePwd(value.trim())) {
                callback(new Error('密码同时包含大写字母、小写字母或数字中的两种及以上，8-16位'))
            } else {
                callback()
                if (this.pwdForm.new2 !== '') {
                    this.$refs.pwdForm.validateField('new2')
                }
            }
        };
        let validatePwdNew2 = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error('请重新输入密码'))
            } else if (value !== this.pwdForm.new) {
                callback(new Error("两次密码不一致"))
            } else {
                callback()
            }
        };

        let validateCodeNew = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error('请输入密码'))
            } else if (!validatePwd(value.trim())) {
                callback(new Error('密码同时包含大写字母、小写字母或数字中的两种及以上，8-16位'))
            } else {
                callback()
                if (this.codeForm.new2 !== '') {
                    this.$refs.codeForm.validateField('new2')
                }
            }
        };
        let validateCodeNew2 = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error('请重新输入密码'))
            } else if (value !== this.codeForm.new) {
                callback(new Error("两次密码不一致"))
            } else {
                callback()
            }
        };
        return {
            editPwdType: 2, // 密码修改类型
            editPwdVisible: false, // 修改密码弹框
            editPwdLoading: false, // 密码修改loading

            // 通过旧密码修改
            pwdForm: {
                old: '',
                new: '',
                new2: ''
            },
            pwdshow: {
                old: false,
                new: false,
                new2: false
            },
            rulesPwd: {
                old: [{ required: true, message: '请输入旧密码', trigger: 'blur'}],
                new: [{ required: true, validator: validatePwdNew, trigger: 'blur'}],
                new2: [{ required: true, validator: validatePwdNew2, trigger: 'blur'}]
            },
            
            // 通过验证码修改
            codeForm: {
                code: '',
                new: '',
                new2: ''
            },
            codeshow: {
                new: false,
                new2: false
            },
            rulesCode: {
                code: [{ required: true, message: '请输入验证码', trigger: 'blur'}],
                new: [{ required: true, validator: validateCodeNew, trigger: 'blur'}],
                new2: [{ required: true, validator: validateCodeNew2, trigger: 'blur'}]
            },

            // 获取验证码
            smsCaptcha: {
                isWaiting: false,
                timer: null,
                second: 60,
                showText: '获取验证码',
            }
        }
    },
    methods: {
        // 修改头像
        beforeUpload(file, fileList) {
            // console.log(file, fileList, 'beforeUpload')
            return false
        },
        // 上传头像
        uploadOnChange(info) {
            console.log(info, 'uploadOnChange')
            let file = info.file
            if (!(/^image\/(.*)$/ig.test(file.type))) {
                this.$message.error('请上传图片')
                return
            }
            // test
            // let reader = new FileReader()
            // reader.onload = function() {
            //     console.log(this.result)
            // }
            // reader.readAsDataURL(file)

            let objectUrl = URL.createObjectURL(file)
            this.$showLoading()
            this.compressImg(objectUrl, (dataUrl) => {
                console.log(dataUrl)
                updateAvatar({
                    avatarStr: dataUrl, // dataUrl,
                    adminId: this.adminId
                }).then(res => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$store.dispatch('getAdminInfo').then(() => {
                            this.$hideLoading()
                        })
                    } else {
                        this.$hideLoading()
                    }
                }).catch(err => {
                    this.$hideLoading()
                    console.warn(err)
                })
            })
        },
        // 压缩头像
        compressImg(src, callback){
            console.log(src)
            let img = new Image()
            img.src = src
            img.onload = function() {
                var canvasEl = document.createElement('canvas');
                let ctx=canvasEl.getContext('2d');
                console.log(img.src.length, img.width,img.height)
                // let initSize=img.src.length;
                let width=img.width;
                let height=img.height;
                let whRate = height / width;

                let create_width, create_height;
                width > 96 ? create_width = 96 : create_width = width;
                create_height = create_width * whRate;
                canvasEl.width = create_width;
                canvasEl.height = create_height;
                console.log('create_width: ', create_width, 'create_height: ', create_height)
                ctx.drawImage(img, 0, 0, create_width, create_height);
                let dataURL = canvasEl.toDataURL("image/jpeg", 0.92);
                callback?callback(dataURL):null; //调用回调函数
            }
            
            
        },

        // 切换修改密码方式
        onChangeType(type) {
            console.log('onChangeType', type);
            this.editPwdType = type
            if (type === 1) {
                this.$refs.pwdForm.clearValidate()
            }
            if (type === 2) {
                this.$refs.codeForm.clearValidate()
            }
        },

        // 确认修改密码弹框
        editPwdOk() {
            console.log('editPwdOk')
            if (this.editPwdType === 2) {
                this.submitPwdForm()
            }
            if (this.editPwdType === 1) {
                this.submitCodeForm()
            }
        },
        // 重置表单 - 通过旧密码修改
        resetPwdForm() {
            this.$refs.pwdForm.resetFields()
        },
        // 提交表单 - 通过旧密码修改
        submitPwdForm() {
            this.$refs.pwdForm.validate(valid => {
                if (valid) {
                    console.log('submit-pwdForm =>', this.pwdForm)
                    this.editPwdLoading = true
                    updatePwd({
                        oldPwd: this.pwdForm.old.trim(),
                        newPwd: this.pwdForm.new.trim(),
                        confirmPwd: this.pwdForm.new2.trim(),
                        adminId: this.adminId,
                    }).then(res => {
                        console.log(res)
                        if (res.code === 200) {
                            this.$message.success('密码修改成功')
                            // this.$success({
                            //     title: '提示',
                            //     content: '密码修改成功',
                            //     centered: true
                            // })
                            this.$refs.pwdForm.resetFields()
                            this.editPwdVisible = false
                        } else {
                            this.$message.error(res.msg || '网络异常')
                        }
                    }).catch(err => {
                        console.warn(err)
                    }).finally(() => {
                        this.editPwdLoading = false
                    })
                }
            })
        },
        // 重置表单 - 通过验证码修改
        resetCodeForm() {
            this.$refs.codeForm.resetFields()
        },
        // 提交表单 - 通过验证码修改
        submitCodeForm() {
            this.$refs.codeForm.validate(valid => {
                if (valid) {
                    console.log('submit-codeForm =>', this.codeForm)
                    this.editPwdLoading = true
                    updatePwdByPhone({
                        captcha: this.codeForm.code.trim(),
                        newPwd: this.codeForm.new.trim(),
                        confirmPwd: this.codeForm.new2.trim(),
                        adminId: this.adminId,
                    }).then(res => {
                        console.log(res)
                        if (res.code === 200) {
                            this.$message.success('密码修改成功')
                            // this.$success({
                            //     title: '提示',
                            //     content: '密码修改成功',
                            //     centered: true
                            // })
                            this.editPwdVisible = false
                            this.$refs.codeForm.resetFields()
                        } else {
                            this.$message.error(res.msg || '网络异常')
                        }
                    }).catch(err => {
                        console.warn(err)
                    }).finally(() => {
                        this.editPwdLoading = false
                    })
                }
            })
        },

        // 获取验证码
        getSmsCode() {
            if (!this.smsCaptcha.isWaiting) {
                this.smsCaptcha.isWaiting = true

                // 重置定时器
                let resetTimer = () => {
                    clearInterval(this.smsCaptcha.timer)
                    this.smsCaptcha.second = 60
                    this.smsCaptcha.showText = '获取验证码'
                    this.smsCaptcha.isWaiting = false
                }

                // 请求接口
                getSmsCaptcha({phone: this.adminCellphone}).then(res => {
                    // console.log(res)
                    if (res.errorCode === 200) {
                        this.$message.success('验证码发送成功!')
                    } else {
                        this.$message.error(res.errorMsg || '网络异常，请稍后重试')
                        resetTimer()
                    }
                }).catch(err => {
                    console.warn(err)
                    resetTimer()
                })

                // 倒计时
                clearInterval(this.smsCaptcha.timer)
                this.smsCaptcha.showText = this.smsCaptcha.second + 's'
                this.smsCaptcha.timer = setInterval(() => {
                    if (this.smsCaptcha.second > 0) {
                        this.smsCaptcha.second --
                        this.smsCaptcha.showText = this.smsCaptcha.second + 's'
                    } else {
                        resetTimer()
                    }
                }, 1000)
            }
        },
    },
}
</script>

<style lang="less" scoped>
.user-profile {
    padding: 4px 44px;
    height: 100%;
    .head-title {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 32px;
        margin-bottom: 24px;
    }
}
.user-wrapper-box {
    padding: 24px 40px 0;
    border: 1px solid #E6E6E6;
    background-color: #fff;
    min-width: 900px;
    min-height: calc(~"100% - 80px");
    display: flex;
    justify-content: center;
    .title {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
    }
    .basic-wrap {
        width: 300px;
        padding: 64px 0;
        position: relative;
    }
    .avatar-wrap {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        .edit-btn {
            margin-left: 24px;
            cursor: pointer;
            .edit-text {
                color: #999;
                font-size: 10px;
            }
            .edit-text:hover {
                color: #0267ED;
            }
        }
    }
    .user-info {
        .text-line {
            display: flex;
            align-items: center;
            margin-bottom: 36px;
            font-size: 12px;
            color: #999999;
            line-height: 18px;
        }
        .item-text {
            margin-left: 32px;
        }
    }
}
.pwd-wrap {
    padding: 0 68px;
    position: relative;
}
.pwd-form {
    margin-top: 24px;
}
.cursor {
    cursor: pointer;
} 
.switch-pwd-type {
    display: inline-flex;
    background: #FFFFFF;
    .item {
        padding: 0 20px;
        line-height: 30px;
        font-size: 14px;
        color: #666666;
        border: 1px solid #C6CBD1;
        cursor: pointer;
        &:first-child{
            border-radius: 3px 0 0 3px;  
        }
        &:last-child{
            border-radius: 0 3px 3px 0;  
        }
        &.active {
            color: #fff;
            background: #0267ED;
            border-color: #0267ED;
        }
    }
}
</style>